import {tns} from "tiny-slider/src/tiny-slider";
import "tiny-slider/src/tiny-slider.scss";

export function sliders() {
    let slider = document.getElementById('main-header-gallery-slider');
    let featured = document.getElementById('featured-gallery');
    let gallery = document.getElementById('product-range-gallery');
    let galleryThumbnails = document.getElementById('product-range-gallery-thumbnails');

    if (slider) {
        let image = slider.querySelectorAll('.image');

        if (image.length > 1) {
            tns({
                container: '#main-header-gallery-slider',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: true,
                navContainer: '#main-header-gallery-nav',
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: true,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
    if (featured) {
        let featuredImage = featured.querySelectorAll('.featured-slide');

        if (featuredImage.length > 1) {
            tns({
                container: '#featured-gallery',
                mode: 'carousel',
                loop: true,
                controls: true,
                controlsContainer: '#featured-gallery-nav',
                nav: false,
                arrowKeys: true,
                items: 1,
                speed: 1000,
                autoplay: false,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
                gutter: 30,
                mouseDrag: true,
                responsive: {
                    768: {
                        items: 2,
                    },
                    992: {
                        items: 3,
                    },
                },
            });
        }
    }
    if (gallery) {
        let image = gallery.querySelectorAll('.gallery-item');

        if (image.length > 1) {
            tns({
                container: '#product-range-gallery',
                mode: 'gallery',
                loop: true,
                controls: false,
                nav: false,
                navContainer: '#product-range-gallery-thumbnails',
                navAsThumbnails: false,
                arrowKeys: false,
                items: 1,
                speed: 2000,
                autoplay: false,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
            });
        }
    }
    if (galleryThumbnails) {
        let thumbnail = galleryThumbnails.querySelectorAll('.thumbnail');

        if (thumbnail.length > 1) {
            tns({
                container: '#product-range-gallery-thumbnails',
                mode: 'carousel',
                loop: true,
                controls: true,
                controlsContainer: '#product-range-gallery-controls',
                nav: false,
                arrowKeys: true,
                items: 2,
                speed: 1000,
                autoplay: false,
                autoplayButton: false,
                autoplayButtonOutput: false,
                autoplayHoverPause: false,
                autoplayTimeout: 5000,
                gutter: 30,
                mouseDrag: true,
                responsive: {
                    576: {
                        items: 3,
                    },
                },
            });
        }
    }
}
